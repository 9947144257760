var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-table",
          {
            ref: "grid",
            attrs: {
              title: "배출구별 주요배출시설 가동(조업)시간",
              tableId: "grid",
              columns: _vm.grid.columns,
              gridHeight: _vm.grid.height,
              data: _vm.grid.data,
              usePaging: false,
              columnSetting: false,
              gridHeightAuto: _vm.param.isFullScreen,
              filtering: false,
              isExcelDown: false,
              editable: _vm.editable,
            },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    !_vm.param.isFullScreen && _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "LBLSAVE", icon: "save" },
                          on: { btnClicked: _vm.saveData },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }